exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-en-tsx": () => import("./../../../src/pages/contact/index.en.tsx" /* webpackChunkName: "component---src-pages-contact-index-en-tsx" */),
  "component---src-pages-contact-index-ru-tsx": () => import("./../../../src/pages/contact/index.ru.tsx" /* webpackChunkName: "component---src-pages-contact-index-ru-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-ru-tsx": () => import("./../../../src/pages/index.ru.tsx" /* webpackChunkName: "component---src-pages-index-ru-tsx" */),
  "component---src-pages-projects-index-en-tsx": () => import("./../../../src/pages/projects/index.en.tsx" /* webpackChunkName: "component---src-pages-projects-index-en-tsx" */),
  "component---src-pages-projects-index-ru-tsx": () => import("./../../../src/pages/projects/index.ru.tsx" /* webpackChunkName: "component---src-pages-projects-index-ru-tsx" */),
  "component---src-pages-skills-index-en-tsx": () => import("./../../../src/pages/skills/index.en.tsx" /* webpackChunkName: "component---src-pages-skills-index-en-tsx" */),
  "component---src-pages-skills-index-ru-tsx": () => import("./../../../src/pages/skills/index.ru.tsx" /* webpackChunkName: "component---src-pages-skills-index-ru-tsx" */),
  "component---src-pages-videos-index-en-tsx": () => import("./../../../src/pages/videos/index.en.tsx" /* webpackChunkName: "component---src-pages-videos-index-en-tsx" */),
  "component---src-pages-videos-index-ru-tsx": () => import("./../../../src/pages/videos/index.ru.tsx" /* webpackChunkName: "component---src-pages-videos-index-ru-tsx" */)
}

